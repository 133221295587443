/* Made with love by Mutiullah Samim*/

@import url('https://fonts.googleapis.com/css?family=Numans');

#containerLogin {
    /*background-image: url('https://getwallpapers.com/wallpaper/full/8/2/2/575638.jpg');*/
    /*background-image: url('/images/background.jpg');*/
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    font-family: 'Numans', sans-serif;
    font-size: 0.8rem;
}

#innerContainer {
    width: 100%;
    margin: 0 auto;
}

.loading {
    width: 50px;
    margin: auto;
}

#phoneIcon {
    padding: 15px;
}

#header {
    margin-top: 50px;
}

#panel {
    background: #1c2120;
    color: #fff;
    width: 100%;
    /*height: 180px;*/
    vertical-align: middle;
    padding-top: 5px;
    margin-top: 10px;
    position: fixed;
    bottom: 0;
}

#container_error {
    width: 80%;
    text-align: center;
    margin: 0 auto;
   /* padding: 10px;
    margin-top: 10px;*/
}

#error_message {
    margin-top: 10px;
    color: #ff5757;
    font-weight: 800;
}

#success_message {
    color: #FFC312;
    font-weight: 800;
}

#fields {
    width: 315px;
    margin: 0 auto;
}

#msisdn {
    width: 200px;
    text-align: center;
}

#logindiv {
    width: 85%;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
}

.container {
    height: 100%;
    align-content: center;
}

.card {
    height: 370px;
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.social_icon span {
    font-size: 60px;
    margin-left: 10px;
    color: #FFC312;
}

.social_icon span:hover {
    color: white;
    cursor: pointer;
}

.card-header h3 {
    color: white;
    text-align: center;
}

.social_icon {
    position: absolute;
    right: 20px;
    top: -45px;
}

.input-group-prepend span {
    width: 50px;
    background-color: #fdbc1c;
    color: black;
    border: 0 !important;
}

input:focus {
    outline: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;

}

.remember {
    color: white;
}

.remember input {
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-right: 5px;
}

.login_btn {
    color: #fff;
    background-color: #fdbc1c;
    width: 320px;
    font-weight: 600;
}

.login_btn:hover {
    color: black;
    background-color: white;
}

.suscribe_btn {
    color: #fff;
    background-color: #ff5757;
    width: 100px;
}

.suscribe_btn:hover {
    color: black;
    background-color: white;
}

.links {
    color: white;
}

.links a {
    margin-left: 4px;
}

#subscribePanel, #formDiv {
    margin-top:20px;
}

.subscribe {
    height: 50px;
}

.accessNow {
    height: 50px;
}